import Vue from 'vue';

const dataObj = {
    apiURL: '/api',
    // apiURL: 'http://192.241.153.71/api',
    // apiURL: 'http://localhost:5654/api',
    userList: [],
    repoList: [],
    branches: [],
    userTitle: '',
    updated: 0,
    user: null,
    repo: null,
    preview: false,
    dataFetcher: null,
    config: {
        branch: null,
        kiwiConfig: '',
    },
    maxTitleLength: 60,
    showChanges: false,
};

const appData = new Vue({
    data: dataObj,
    computed: {
        sortedBranches() {
            return this.branches.slice().sort(this.sortBranches);
        },
        sortedSuccessfulBranches() {
            return this.branches.slice().filter((b) => {
                return b.success === true;
            }).sort(this.sortBranches);
        },
    },
    created: function() {
        // this.fetchData();
    },
    watch: {
        user() {
            this.repoList = [];
            this.branches = [];
            this.updated = 0;
            this.userTitle = '';
            this.fetchData();
        },
        repo() {
            this.branches = [];
            this.updated = 0;
            this.fetchData();
        },
    },
    methods: {
        fetchData() {
            // console.log('fetchData()', this.user, this.repo);
            // Clear the timer
            if (this.dataFetcher) {
                clearTimeout(this.dataFetcher);
                this.dataFetcher = null;
            }

            let self = this;
            let url = this.apiURL + '/users';

            if (this.userList.length && this.user) {
                url += '/' + this.user;
                if (this.repoList.length && this.repo) {
                    url += '/' + this.repo;
                }
            }

            url += '?seen=' + this.updated;

            fetch(url)
                .then(r => {
                    // console.log('fetchData()', r);
                    if (r.ok) {
                        return r.json();
                    }
                    throw new Error('no update needed');
                })
                .then(j => {
                    this.handleResponse(j);
                    this.dataFetcher = setTimeout(function() { self.fetchData(); }, 5000);
                }).catch(e => {
                    // console.log('retrying fetchData()', e);
                    this.dataFetcher = setTimeout(function() { self.fetchData(); }, 5000);
                });
        },
        handleResponse(resp) {
            // console.log('resp:', JSON.stringify(response, null, 2));

            if ('last_updated' in resp) {
                this.updated = resp.last_updated;
            }
            if ('user_list' in resp) {
                this.userList = resp.user_list;
                if (!this.user || this.userList.indexOf(this.user) === -1) {
                    this.user = this.userList[0];
                } else if (!this.repoList.length) {
                    this.fetchData();
                }
            }
            if ('repo_list' in resp && this.isActiveUser(resp)) {
                this.repoList = resp.repo_list;
                if (!this.repo || this.repoList.indexOf(this.repo) === -1) {
                    this.repo = this.repoList[0];
                } else if (!this.branches.length) {
                    this.fetchData();
                }
            }
            if ('user_title' in resp && this.isActiveUser(resp)) {
                this.userTitle = resp.user_title;
            }
            if ('branches' in resp && this.isActiveRepo(resp)) {
                this.preview = resp.repo_preview;
                this.mergeBranches(resp);
                this.$emit('branches_updated');
            }
        },
        mergeBranches(resp) {
            let rb = [];
            for (let b of resp.branches) {
                let lb = this.getBranch(b.name);
                if (lb !== null) {
                    this.mergeProps(lb, b);
                } else {
                    this.branches.push(b);
                }
                rb.push(b.name);
            }

            // Only remove local branches if its a full update
            if (!resp.branches_full) {
                return;
            }

            for (let i = 0; i < this.branches.length; i++) {
                let b = this.branches[i];
                if (rb.indexOf(b.name) === -1) {
                    this.branches.splice(i, 1);
                    i--;
                }
            }
        },
        mergeProps(base, obj) {
            for (let prop in obj) {
                if (!obj.hasOwnProperty(prop)) {
                    continue;
                }
                if (typeof obj[prop] === 'object') {
                    if (prop in base && base[prop] !== null) {
                        this.mergeProps(base[prop], obj[prop]);
                    } else {
                        base[prop] = obj[prop];
                    }
                    continue;
                }

                if (base[prop] !== obj[prop]) {
                    base[prop] = obj[prop];
                }
            }
        },
        isActiveUser(resp) {
            return resp.user === this.user;
        },

        isActiveRepo(resp) {
            return this.isActiveUser(resp) && resp.repo === this.repo;
        },
        getBranchFromSHA(sha) {
            this.branches.forEach((branch) => {
                if (branch.github_sha === sha) {
                    return branch;
                }
            });
            return null;
        },
        getBranch(name) {
            for (let i in this.branches) {
                if (this.branches[i].name === name) {
                    return this.branches[i];
                }
            }
            return null;
        },
        updateQuery(router, route) {
            const query = {};
            if (this.user !== null && this.user !== 'kiwiirc') {
                query.user = this.user;
            }
            if (this.repo !== null && this.repo !== 'kiwiirc') {
                query.repo = this.repo;
            }

            if (route.query.user !== query.user || route.query.repo !== query.repo) {
                router.push({ path: route.path, query });
            }
        },
        sortBranches(a, b) {
            // master first
            if (a.name === 'master') {
                return -1;
            }
            if (b.name === 'master') {
                return 1;
            }

            // branches before pulls
            if (
                (a.name.startsWith('pull/') && !b.name.startsWith('pull/')) ||
                (!a.name.startsWith('pull/') && b.name.startsWith('pull/'))
            ) {
                return (!a.name.startsWith('pull/') && b.name.startsWith('pull/')) ? -1 : 1;
            }

            // sort pulls decending
            if (a.name.startsWith('pull/') && b.name.startsWith('pull/')) {
                let an = parseInt(a.name.split('/')[1]);
                let bn = parseInt(b.name.split('/')[1]);
                return an > bn ? -1 : 1;
            }

            // sort everything else alphabetically ascending
            return a < b ? -1 : 1;
        },
    },
});

export default appData;
