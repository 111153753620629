<template>
    <div>
        <div v-if="appData.user === 'kiwiirc' && appData.repo === 'kiwiirc'" class="repo_message">
            Welcome to Kiwi IRC Preview service.<br/>
            To preview a branch/pull_request click<i class="icon icon-eye" style="font-size: 150%"></i>next to the branch of interest.<br/>
            Change the kiwi config.json for your IP address<router-link to="/conf" class="repo_message_link">[here]</router-link>to test different configurations.
        </div>
        <table v-if="appData.user !== null && appData.repo !== null" class="repo_table">
            <thead v-if="appData.sortedBranches.length > 0">
                <th class="nopadding" />
                <th style="min-width: 500px;">Branch / Pull-Request</th>
                <th class="nopadding"><i class="icon icon-github-circled" style="font-size: 150%"></i></th>
                <th style="min-width: 180px;">Last Build</th>
                <th style="min-width: 120px;">Owner</th>
                <th />
            </thead>
            <tr
                v-for="item in appData.sortedBranches"
                :key="item.id"
                class="branch_row"
                :style="{'background': itemBackground(item)}"
            >

                <!-- Status -->
                <td style="padding: 0;">
                    <i v-if="item.running" class="icon icon-arrows" v-tooltip.bottom="'Build Running...'" style="color: #ffaa00"></i>
                    <i v-else-if="item.success" class="icon icon-ok" v-tooltip.bottom="'Build Successful<br/>Duration: ' + item.stats.duration + ' seconds<br/>Hosted SHA: ' + item.hosted_sha" style="color: #00aa00"></i>
                    <i v-else class="icon icon-cancel" v-tooltip.bottom="'Failed ' + itemFailedStage(item)" style="color: #aa0000"></i>
                </td>

                <!-- Branch / Pull-Request -->
                <td class="align-left">
                    <a class="repo_ref" :href="getGithubUrl(item)" target="_blank">{{ formatRef(item.name) }}</a>
                    <span v-if="item.title && item.title.length <= appData.maxTitleLength"> {{ item.title }}</span>
                    <span v-else-if="item.title" v-tooltip.bottom="item.title"> {{ formatTitle(item.title) }}</span>
                </td>

                <!-- SHA Match -->
                <td class="nopadding" style="text-align: center;">
                    <i v-if="item.github_sha != item.hosted_sha" class="icon icon-not-equal" v-tooltip.bottom="'SHA Differ'" style="color: #aa0000"></i>
                    <i v-else-if="item.stats.merge === 1"  class="icon icon-alert" v-tooltip.bottom="'Merge Failed'" style="color: #ffaa00"></i>
                    <i v-else class="icon icon-ok" v-tooltip.bottom="'SHA Match'" style="color: #00aa00"></i>
                </td>

                <!-- Last Build -->
                <td>
                    <span v-if="item.date === 0">Never</span>
                    <timeago v-else :datetime="item.date*1000" :auto-update="60" v-tooltip.bottom="new Date(item.date*1000).toLocaleString()"></timeago>
                </td>

                <!-- Owner -->
                <td>{{item.owner}}</td>

                <!-- Preview Links -->
                <td class="align-left" style="padding: 0;">
                    <!-- Link to github -->
                    <a v-if="isPullRequest(item.name)" :href="getGithubUrl(item)" target="_blank" v-tooltip.bottom="'View Pull-Request'"><i class="icon icon-git-pull"></i></a>
                    <a v-else :href="getGithubUrl(item)" target="_blank" v-tooltip.bottom="'View Branch'"><i class="icon icon-git-branch"></i></a>

                    <!-- Link to log -->
                    <a v-if="item.log == 1" :href="'/logs/' + appData.user + '_' + appData.repo + '_' + item.clean_name + '.txt'" target="_blank" v-tooltip.bottom="'View Build Log'">
                        <i class="icon icon-log"></i>
                    </a>

                    <!-- Link to zip -->
                    <a v-if="item.success == 1" :href="getDownloadUrl(item)" v-tooltip.bottom="'Download Zip'">
                        <i class="icon icon-zip"></i>
                    </a>

                    <!-- Link to preview -->
                    <a v-if="appData.preview && item.success == 1" :href="'/' + appData.user + '/' + appData.repo + '/' + item.clean_name + '/'" target="_blank" v-tooltip.bottom="'View Preview'">
                        <i class="icon icon-eye" style="font-size: 150%"></i>
                    </a>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>

import appData from '@/libs/appData';

export default {
    name: 'Home',
    computed: {
        appData() {
            return appData;
        },
        statsMax() {
            let maxHigh = 0;
            let maxLow = 0;
            for (let item of this.appData.branches) {
                let sum = item.stats.add + item.stats.del;
                if (sum > maxHigh) {
                    maxHigh = sum;
                }
            }
            for (let item of this.appData.branches) {
                let sum = item.stats.add + item.stats.del;
                if (sum < maxHigh / 2 && sum > maxLow) {
                    maxLow = sum;
                }
            }
            return { high: maxHigh, low: maxLow };
        },
        compactView() {
            return this.uiWidth < 1100;
        },
    },
    methods: {
        itemBackground(item) {
            if (!this.appData.showChanges) {
                return '';
            }
            let max = this.statsMax;
            let sum = item.stats.add + item.stats.del;
            let size = sum / (sum <= max.low ? max.low : max.high) * (sum <= max.low ? 50 : 100);
            let ratio = item.stats.add / sum * 100;
            if (!size) {
                return '';
            }
            return 'linear-gradient(to right, rgba(0, 255, 0, 0.15) ' + ratio + '%, rgba(255, 0, 0, 0.15) ' + ratio + '%) left/' + size + '% no-repeat';
        },
        itemFailedStage(item) {
            // FailStateNone // 0
            // FailStateLint // 1
            // FailStateTest // 2
            // FailStateBuild // 3
            switch (item.stats.failure) {
            case 1:
                return 'Lint';
            case 2:
                return 'Test';
            case 3:
                return 'Build';
            default:
                return 'Unexpectedly';
            }
        },
        formatTitle(title) {
            if (title) {
                let shortTitle = title.substr(0, this.appData.maxTitleLength);
                return shortTitle.substr(0, Math.min(shortTitle.length, shortTitle.lastIndexOf(' '))) + '...';
            }
            return null;
        },
        formatRef(ref) {
            if (this.isPullRequest(ref)) {
                return '#' + (/^pull\/(\d+)$/.exec(ref))[1];
            }
            return ref;
        },
        isPullRequest(ref) {
            return (/^pull\/\d+$/.test(ref));
        },
        shortenSha(sha) {
            return (sha != null) ? sha.substr(0, 7) : '';
        },
        cleanRef(ref) {
            return ref ? ref.replace('/', '-') : 'none';
        },
        getGithubUrl(item) {
            return 'https://github.com/' +
            this.appData.user + '/' + this.appData.repo +
            (this.isPullRequest(item.name) ? '/' : '/tree/') + item.name;
        },
        getDownloadUrl(item) {
            if (this.appData.user === 'kiwiirc') {
                return `/zips/${this.appData.repo}_${item.clean_name}.zip`;
            }
            return `/zips/${this.appData.user}_${this.appData.repo}_${item.clean_name}.zip`;
        },
    },
};
</script>
<style>
.repo_message {
    margin: 20px auto;
    border-collapse: collapse;
    width: fit-content;
    min-width: 1058px;
    line-height: 30px;
}
.repo_message_link {
    padding: 6px;
    cursor: pointer;
    font-weight: bold;
}
.repo_table {
    margin: 20px auto;
    border-collapse: collapse;
}
.repo_table caption {
    font-size: 130%;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
}
.repo_table td, th {
    padding: 4px 10px 4px 10px;
}
.repo_table tr:hover {
    background-color: #e6e6e6 !important;
}
.repo_table a {
    display: inline-block;
}
.repo_table i {
    font-size: 20px;
    width: 20px;
    height: 20px;
    margin: 0 2px 0 2px;
    vertical-align: middle;
    text-align: center;
}
.repo_table .nopadding {
    padding: 4px 0 4px 0;
}
.repo_table .repo_ref {
    font-weight: bold;
}
.repo_table .align-left {
    text-align: left;
}
.tooltip {
    display: block !important;
    z-index: 10000;
}
.tooltip .tooltip-inner {
    background: #e6e6e6;
    color: black;
    border: 1px grey solid;

    /* border-radius: 16px; */
    padding: 5px 10px 4px;
}
.tooltip .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 8px;
    border-color: grey;
    z-index: 1;
}
.tooltip[x-placement^="top"] {
    margin-bottom: 5px;
}
.tooltip[x-placement^="top"] .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}
.tooltip[x-placement^="bottom"] {
    margin-top: 10px;
}
.tooltip[x-placement^="bottom"] .tooltip-arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}
.tooltip[x-placement^="right"] {
    margin-left: 5px;
}
.tooltip[x-placement^="right"] .tooltip-arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}
.tooltip[x-placement^="left"] {
    margin-right: 5px;
}
.tooltip[x-placement^="left"] .tooltip-arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}
.tooltip.popover .popover-inner {
    background: #f9f9f9;
    color: black;
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(black, 0.1);
}
.tooltip.popover .popover-arrow {
    border-color: #f9f9f9;
}
.tooltip[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
}
.tooltip[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
}
</style>
