import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/components/Home';
const ConfigEdit = () => import(/* webpackPreload: true */ '@/components/ConfigEdit');

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
        },
        {
            path: '/conf',
            name: 'ConfigEdit',
            component: ConfigEdit,
        },
    ],
    mode: 'history',
});
