<template>
    <div id="app">
        <div class="builds-header">
            <div class="builds-header-title">Kiwi IRC Preview Builds</div>
            <div class="builds-menu">
                <div v-if="appData.userList.length > 1" class="builds-menu-item">User:
                    <select v-model="appData.user" :disabled="$router.currentRoute.path === '/conf'">
                        <option v-for="user in appData.userList" :key="user">
                            {{ user }}
                        </option>
                    </select>
                </div>
                <div class="builds-menu-item">Repo:
                    <select v-model="appData.repo" :disabled="$router.currentRoute.path === '/conf'">
                        <option v-for="user in appData.repoList" :key="user">
                            {{ user }}
                        </option>
                    </select>
                </div>
                <div v-if="$router.currentRoute.path === '/conf'" class="builds-menu-item builds-menu-conf-close" style="text-decoration: none; color: inherit; width: 172px;">
                    <!-- <router-link v-if="$router.currentRoute.path !== '/conf'" to="/conf">Edit IP Based Config</router-link> -->
                    <router-link to="/">Close IP Based Config</router-link>
                </div>
            </div>
        </div>
        <div class="builds-container"><router-view/></div>
    </div>
</template>

<script>
import '@/assets/css/icons.css';
import appData from '@/libs/appData';

export default {
    name: 'App',
    data() {
        return {
        };
    },
    computed: {
        appData() {
            return appData;
        },
    },
    watch: {
        'appData.user'() {
            appData.updateQuery(this.$router, this.$route);
        },
        'appData.repo'() {
            appData.updateQuery(this.$router, this.$route);
        },
    },
    created() {
        if (this.$route.query.user) {
            appData.user = this.$route.query.user;
        }
        if (this.$route.query.repo) {
            appData.repo = this.$route.query.repo;
        }
        if (!this.$route.query.user && !this.$route.query.repo) {
            appData.fetchData();
        }
    },
};
</script>

<style>
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}
body {
    overflow: hidden;
    min-width: 1096;
    margin: 0;
}
a {
    text-decoration: none;
    color: inherit;
}
.builds-header {
    display: flex;
    align-items: center;
    text-align: center;
    background: #6f932c; /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover, #6f932c 0%, #425e16 110%); /* FF3.6+ */
    background: -webkit-gradient(radial, center center, 0, center center, 100%, color-stop(0%, #6f932c), color-stop(110%, #425e16)); /* Chrome,Safari4+ */
    background: -webkit-radial-gradient(center, ellipse cover, #6f932c 0%, #425e16 110%); /* Chrome10+,Safari5.1+ */
    background: -o-radial-gradient(center, ellipse cover, #6f932c 0%, #425e16 110%); /* Opera 12+ */
    background: -ms-radial-gradient(center, ellipse cover, #6f932c 0%, #425e16 110%); /* IE10+ */
    background: radial-gradient(ellipse at center, #6f932c 0%, #425e16 110%); /* W3C */
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#6f932c', endColorstr='#425e16', GradientType=1); /* IE6-9 fallback on horizontal gradient */
    border-radius: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 600;
    color: #fff;
    width: 100%;
    height: 40px;
}
.builds-header-title {
    position: absolute;
    top: 12px;
    left: 10px;
}
.builds-menu {
    width: 100%;
}

.builds-menu-item {
    display: inline-block;
    padding: 11px;
    margin: 0 2.5px;
    background-color: rgba(255, 255, 255, 0.15);
}
.builds-menu-item select {
    min-width: 150px;
}
.builds-menu-item:first-of-type {
    margin-left: 0;
}
.builds-menu-item:last-of-type {
    margin-right: 0;
}
.builds-menu-conf-close {
    background-color: rgba(255,0,0,.45);
}
.builds-container {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
}

</style>
