// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import router from './router';
import appData from '@/libs/appData';
import VTooltip from 'v-tooltip';
import VueTimeago from 'vue-timeago';

Vue.use(VTooltip);
Vue.use(VueTimeago, {});

Vue.config.productionTip = false;

window.appData = appData;

/* eslint-disable no-new */
const builds = new Vue({
    el: '#app',
    router,
    components: { App },
    template: '<App/>',
});
// router.replace('/');

window.builds = builds;
